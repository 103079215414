<template>
  <div class="scode">
    <div class="boxsdd">
      <span class="fan1" @click="toindeix()">《 返回首页</span>
      <span class="fan2">{{typescode==1?'确认订单':typescode==2?'收银':'扫码支付'}}</span>
    </div>
    <div class="isodcodebox" v-if="typescode==1">
      <p class="idfoso"><span>.</span>商品信息</p>
      <div class="flex_box">
        <div class="flex2 opskd ">
          <div class="flex_box">
            <img :src="goodslist[paic].src" class="flex1">
            <div class="flex2 djfis">
              <p>{{goodslist[paic].name}} X 1</p>
              <p>￥:{{goodslist[paic].price}}</p>
            </div>
          </div>
          <div class="osidof">
            赠品：无
          </div>
        </div>
        <div class="flex1 leftscode">
          <p>商品总金额：￥{{goodslist[paic].price}}</p>
          <p>优惠金额：￥0</p>
          <p>结算金额：￥{{goodslist[paic].price}}</p>
        </div>
      </div>
      <div class="isdbutton">
        <p>应付金额<span>￥{{goodslist[paic].price}}</span></p>
        <div class="istobutton" @click="typescode=2">提交订单</div>
      </div>
    </div>
    <div class="odebox2" v-if="typescode==2">
      <p class="idfoso"><span>.</span>收银信息</p>
      <div class="osdpes">
        <img src="../assets/img/yes.png" alt="">
        <p>订单提交成功，只差付款啦~</p>
      </div>
      <div class="istozhifu">
        <p>选择支付方式</p>
        <div class="flex_box sdfas">
          <div class="flex1">
            <span class="isdjospan" :class="istype==1?'cor':''" @click="istype=1"><img src="../assets/img/zfb.png">
              <span> 支付宝</span></span>
          </div>
          <div class="flex1">
            <span class="isdjospan" :class="istype==2?'cor':''" @click="istype=2"><img src="../assets/img/wx.png"><span>
                微信</span></span>
          </div>
          <div class="flex1">
            <span class="isdjospan" :class="istype==3?'cor':''" @click="istype=3"><img
                src="../assets/img/yhk.png"><span>招商银行卡</span></span>
          </div>

        </div>
        <div class="flex_box mt50">
          <div class="flex1">
            <div class="oclooo " @click="typescode=1">取 消</div>
          </div>
          <div class="flex1">
            <div class="istobutton " @click="typescode=3">确认支付</div>
          </div>
        </div>
      </div>
    </div>
    <div class="odebox3" v-if="typescode==3">
      <div class="idsjof">
        <div class="flex_box mb50">
          <div class="flex1">
            <img src="../assets/image/andrord.png" alt="">
            <p>请打开应用APP扫描二维码支付</p>
          </div>
          <div class="flex1">
            <img src="../assets/img/smzf.png" alt="">
          </div>
        </div>
        <div class="mt50">
          <div class="flex_box ">
            <div class="flex1">
              <div class="oclooo w200" @click="typescode=2">取 消</div>
            </div>
            <div class="flex1">
              <div class="istobutton w200" @click="toindeix()">已完成支付</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'b-lodgin',
  props: [],
  data () {
    return {
      istype: 0,
      typescode: 1,
      paic: 1,
      goodslist: [
        { id: 1, name: '美妍集臻宠焕妍系列(套装)', price: '15800', src: require('../assets/img/goods1.jpg') },
        { id: 2, name: '美妍集玻尿酸保湿面膜', price: '480', src: require('../assets/img/goods2.jpg') },
        { id: 3, name: '美妍集水能保湿喷雾', price: '128', src: require('../assets/img/goods3.jpg') },
        { id: 4, name: '美妍集臻致润颜养洁CP组合', price: '100', src: require('../assets/img/goods4.jpg') },
        { id: 5, name: '四季面膜组合', price: '1500', src: require('../assets/img/goods5.jpg') },
        { id: 6, name: '美妍集臻致焕妍倍润肌底液', price: '480', src: require('../assets/img/goods6.jpg') },
        { id: 7, name: '美妍集轻透防晒乳', price: '280', src: require('../assets/img/goods7.jpg') },
        { id: 8, name: '美妍集防护三部曲组合', price: '518', src: require('../assets/img/goods8.jpg') },
        { id: 9, name: '美妍集臻润精华液', price: '298', src: require('../assets/img/goods9.jpg') },
        { id: 10, name: '美妍集臻采凝润美肌BB霜', price: '198', src: require('../assets/img/goods10.jpg') },
      ]
    };
  },
  created () {
    this.paic = this.$route.query.id - 1
  },
  methods: {
    toindeix () {
      this.$router.push({
        path: '/index',
      })
    },
  }
}
</script>

<style lang="scss" scope>
@import "../assets/lodgin.scss";
</style>