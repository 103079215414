<template>
  <div class="lodgin">
    <el-dialog title="注册协议" center :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <div class="isopens">
        <p>
          上海恒裕华平台注册协议（以下又称“我们”或“上海恒裕华”或“甲方”，）在此特别提醒用户（以下简称“您”或“乙方”）在使用我们在上海恒裕华平台上提供的网络服务前仔细阅读《上海恒裕华平台注册协议》（下称“本协议”）中的各个条款，包括但不限于免除或者限制上海恒裕华责任的条款、对用户权利进行限制的条款以及约定争议解决方式、司法管辖的条款。
        </p>
        <p>如您同意本协议的全部条款，请按照页面上的提示完成全部的注册程序。您在进行注册过程中点击“提交注册”按钮即表示用户愿意接受本协议项下的全部条款，请您在进行注册时注意此步骤并选择接受或者不接受本协议。</p>
        <p>本协议条款可由我们适时更新，本协议一旦发生变动，我们将会在相关的页面上提示以让用户及时获得更新的协议内容，修改后的服务协议一旦在页面上公布即有效代替原来的协议。</p>
        <p>您可随时访问本页面查阅最新服务协议。如您不同意本服务协议及或随时对其的修改，请停止使用我们提供的服务，否则即视同您同意并完全接受修订后的协议版本。</p>
        <p>
          上海恒裕华网络科技有限公司（以下又称“我们”或“上海恒裕华”或“甲方”，）在此特别提醒用户（以下简称“您”或“乙方”）在使用我们在上海恒裕华平台上提供的网络服务前仔细阅读《上海恒裕华平台注册协议》（下称“本协议”）中的各个条款，包括但不限于免除或者限制上海恒裕华责任的条款、对用户权利进行限制的条款以及约定争议解决方式、司法管辖的条款。
        </p>
        <p>如您同意本协议的全部条款，请按照页面上的提示完成全部的注册程序。您在进行注册过程中点击“提交注册”按钮即表示用户愿意接受本协议项下的全部条款，请您在进行注册时注意此步骤并选择接受或者不接受本协议。</p>
        <p>本协议条款可由我们适时更新，本协议一旦发生变动，我们将会在相关的页面上提示以让用户及时获得更新的协议内容，修改后的服务协议一旦在页面上公布即有效代替原来的协议。</p>
        <p>您可随时访问本页面查阅最新服务协议。如您不同意本服务协议及或随时对其的修改，请停止使用我们提供的服务，否则即视同您同意并完全接受修订后的协议版本。</p>
      </div>

    </el-dialog>
    <!-- <div class="djfi">.</div> -->
    <div class="opends">
      <span class="fan1" @click="toindeix()">《 返回</span>
      <span class="fan2">{{paic==1?'用户登录':'用户注册'}}</span>
    </div>
    <div class="loginbox">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="用户账号">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item :label="paic==1?'用户密码':'设置密码'">
          <el-input v-model="form.mima"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" v-if="paic==2">
          <el-input v-model="form.mima"></el-input>
        </el-form-item>
        <div class="nois" v-if="paic==2">
          <el-checkbox v-model="checked"></el-checkbox>
          <span @click="sidfj()" class="cursor">《注册协议》</span>
        </div>
        <div class="isbuton" @click="onSubmit">{{paic==1?'登 录':'注 册'}}</div>

        <!-- <el-button type="success" @click="hios()">{{paic==2?'去登录':'去注册'}}</el-button> -->

      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  name: 'b-lodgin',
  props: [],
  data () {
    return {
      form: {
        name: '',
        mima: ''
      },
      paic: 1,
      checked: false,
      dialogVisible: false
    };
  },
  created () {
    this.paic = this.$route.query.id
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    sidfj () {
      this.dialogVisible = true
    },
    toindeix () {
      this.$router.push({
        path: '/index',
      })
    },
    hios () {
      this.paic = this.paic == 1 ? 2 : 1
    },
    onSubmit () {
      let a = '注册成功'
      if (!this.checked && this.paic == 2) {
        this.$message({
          message: '请勾选注册协议',
          type: 'danger'
        });
        return
      }
      if (this.form.name.length == 11 && this.form.mima != '') {
        if (this.paic == 1) {
          a = '登录成功'
        } else {
          a = '注册成功'
        }
        this.$message({
          message: a,
          type: 'success'
        });
        this.$router.push({
          path: '/index',
        })
      } else {
        this.$message({
          message: '请输入正确的手机号/密码 ',
          type: 'danger'
        });
      }

    },
  }
}
</script>

<style lang="scss" scope>
@import "../assets/lodgin.scss";
</style>